import React from "react";
import { CircularProgress, Backdrop } from "@mui/material";
function LoadingSpinnerFullScreen(props)
{
    return (
        <Backdrop
            sx={{
                color: "#374e9a",
                backgroundColor: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
                display: "flex",
                flexDirection: "column",
            }}
            open={props.isLoading}
        >
            <CircularProgress sx={{marginBottom:"10px"}} color="inherit" />
            Loading...
        </Backdrop>
    );
}

export default LoadingSpinnerFullScreen;
