import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import { AuthContextProvider } from './store/auth-context'
import { SocketContextProvider } from './store/socket-context';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AuthContextProvider>
        <SocketContextProvider>
            <BrowserRouter>
                <SnackbarProvider autoHideDuration={3000} maxSnack={5}>
                    <App />
                </SnackbarProvider>
            </BrowserRouter>
        </SocketContextProvider>
    </AuthContextProvider>

);


