import React, { useState } from "react";

const AuthContext = React.createContext({
    token: '',
    profileData: '',
    role: '',
    isLoggedIn: false,
    logIn: (token, profileData) => { },
    logOut: () => { }
})

export const AuthContextProvider = (props) =>
{
    const initialToken = localStorage.getItem("token");
    const initialProfileData = localStorage.getItem("profileData");
    const initialRole = localStorage.getItem("role");
    const [token, setToken] = useState(initialToken);
    const [profileData, setProfileData] = useState(JSON.parse(initialProfileData));
    const [userIsLoggedIn, setUserIsLoggedIn] = useState(!!initialToken)
    const [role, setRole] = useState(initialRole)


    const loginHandler = (token, profileData) =>
    {
        setToken(token)
        setProfileData(profileData)
        if (profileData)
        {
            setRole(profileData.role);
            localStorage.setItem("role", profileData.role);
        }

        localStorage.setItem("token", token);
        localStorage.setItem("profileData", JSON.stringify(profileData));

        setUserIsLoggedIn(true)
    }

    const logOutHandler = () =>
    {
        setToken(null);
        setUserIsLoggedIn(false);
        setRole(null)
        localStorage.clear()
      
    }


    const contextValue = {
        token: token,
        profileData: profileData,
        role: role,
        isLoggedIn: userIsLoggedIn,
        logIn: loginHandler,
        logOut: logOutHandler
    }


    return (
        <AuthContext.Provider value={contextValue}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthContext;