import React, { useEffect, useState, useContext } from "react";
import io from "socket.io-client";
import moment from "moment";

import AuthContext from "./auth-context";

//these context for manage all sockets requests and data
const SocketContext = React.createContext({
    newNotification: false,
    newGeneralNotification: false,
    hospitalNumberOfNotification: 0,
    approvalRequestStatusData: [],
    newApprovalRequestData: [],
    generalNotificationData: [],
    sendApprovalRequest: (data) => { },
    respondToApprovalRequest: (data) => { },
    companyContractNotification: (data) => { },
    removeNotification: () => { },
    removeGeneralNotificationData: () => { },
    removeGeneralNotification: () => { },
    removeNewApprovalStatus: () => { },
    removeNewApprovalRequest: () => { },
    handleRemoveHospitalNotificationMark: () => { },
    handleSendUsageNotification: () => { },
})

export const SocketContextProvider = (props) =>
{
    const authCtx = useContext(AuthContext)
    const socket = io.connect("https://swagrass.herokuapp.com/");
    const [newNotification, setNewNotification] = useState(localStorage.getItem("newNotification") ? JSON.parse(localStorage.getItem("newNotification")) : false);
    const [newGeneralNotification, setNewGeneralNotification] = useState(
        localStorage.getItem("newGeneralNotification")
            ? JSON.parse(localStorage.getItem("newGeneralNotification"))
            : false
    );

    const [hospitalNumberOfNotification, setHospitalNumberOfNotification] = useState(0);
    useEffect(() =>
    {
        if (authCtx.isLoggedIn)
        {
            setHospitalNumberOfNotification(localStorage.getItem("hospitalNumberOfNotification")
                ? JSON.parse(localStorage.getItem("hospitalNumberOfNotification")) + authCtx.profileData.emergencyRequests
                : 0 + authCtx?.profileData.emergencyRequests)
        }
    }, [])

    const [approvalRequestStatusData, setApprovalRequestStatusData] = useState([])
    const [newApprovalRequestData, setNewApprovalRequestData] = useState(localStorage.getItem("newApprovalRequest") ? JSON.parse(localStorage.getItem("newApprovalRequest")) : [])
    const [generalData, setGeneralData] = useState(localStorage.getItem("generalNotification") ? JSON.parse(localStorage.getItem("generalNotification")) : [])
    // console.log("hospitalNumberOfNotification", hospitalNumberOfNotification)
    useEffect(() =>
    {
        if (authCtx.isLoggedIn)
        {
            if (authCtx.profileData.emergencyRequests > 0) setNewNotification(true);
            if (authCtx.profileData.generalNotificaions > 0) setNewGeneralNotification(true);
        }
        // console.log("check new notification when login")
    }, [authCtx])
    useEffect(() =>
    {
        // console.log("use effect")
        if (authCtx.role === "superAdmin" || authCtx.role === "doctor")
        {
            let roomData = {
                role: authCtx.role,
                userId: authCtx.profileData.userId
            }
            socket.emit("joinNewRoom", roomData, (res) =>
            {
                // console.log('joinNewRoom', res)
                if (res !== "success")
                {
                    socket.emit("joinNewRoom", roomData, (res) =>
                    {
                        // console.log('joinNewRoom again', res);
                    });
                }
            })
            socket.on("newApprovalRequest", (data =>
            {
                // console.log(data, 'newApprovalRequest')
                setNewNotification(true);
                localStorage.setItem("newNotification", JSON.stringify(true))
                data.id = data.itemId;
                let tempArray = Array.isArray(newApprovalRequestData) ? newApprovalRequestData : [];
                let tempSet = new Set([...tempArray, { ...data }]);
                setNewApprovalRequestData([...tempSet]);
                localStorage.setItem("newApprovalRequest", JSON.stringify([...tempSet]));
            }));
            socket.on("approvalRequestStatus", (data =>
            {
                // let tempSet = new Set([data, ...approvalRequestStatusData]);
                setApprovalRequestStatusData([data]);
                // localStorage.setItem("approvalRequestStatus", JSON.stringify([...tempSet]));
            }));
            socket.on("sendNotification", (data =>
            {
                // console.log("on sendNotification", data)
                let temp = {
                    ...data,
                    new: true
                }
                setNewGeneralNotification(true);
                localStorage.setItem("newGeneralNotification", JSON.stringify(true))
                let tempSet = new Set([temp, ...generalData]);
                setGeneralData([...tempSet]);
                localStorage.setItem("generalNotification", JSON.stringify([...tempSet]));
            }));
            socket.on("sendUsageNotification", (data =>
            {
                // console.log("sendUsageNotification", data)
                setNewGeneralNotification(true);
                localStorage.setItem("newGeneralNotification", JSON.stringify(true))
            }));
        }

    }, [socket, newApprovalRequestData, authCtx.role, generalData, authCtx.profileData, approvalRequestStatusData])

    useEffect(() =>
    {
        if (authCtx.role === "hospital")
        {
            let roomData = {
                role: "hospital",
                userId: authCtx.profileData.userId
            }
            socket.emit("createRoom", roomData, (res) =>
            {
                // console.log('createRoom', res)
                if (res !== "success")
                {
                    socket.emit("createRoom", roomData, (res) =>
                    {
                        // console.log('createRoom again', res);
                    });
                }
            })
            socket.on("approvalRequestStatus", (data =>
            {
                // console.log("new respo", data)
                const now = new Date();
                let respondDate = moment(now).format("DD/MM/YYYY");
                let respondTime = moment(now).format("hh:mm A");
                data.respondDate = respondDate;
                data.respondTime = respondTime;
                setNewNotification(true);
                setHospitalNumberOfNotification((i => i + 1));
                localStorage.setItem("hospitalNumberOfNotification", JSON.stringify(hospitalNumberOfNotification + 1))
                localStorage.setItem("newNotification", JSON.stringify(true))
                setApprovalRequestStatusData([data]);
            }));
        }
    }, [socket, approvalRequestStatusData, newApprovalRequestData, authCtx.role, authCtx.profileData, hospitalNumberOfNotification])

    const handleSendApprovalRequest = (data, getResponse) =>
    {
        socket.emit("sendApprovalRequest", data, (res) =>
        {
            // console.log("res", res)
            // console.log("data", data)
            if (res.usageNotification === true || res.usageNotification === 'true')
            {
                handleSendUsageNotification();
            }
            getResponse(res);
        });
    }
    const handleRespondToApprovalRequest = (data, showResponse) =>
    {
        socket.emit("respondToApprovalRequest", data, (res) =>
        {
            // console.log("res", res)
            showResponse(res.message ? res.message : res)
        });
    }
    const handleRemoveNotification = () =>
    {
        setNewNotification(false)
        localStorage.setItem("newNotification", JSON.stringify(false))
        let t = authCtx.profileData;
        t.emergencyRequests = 0;
        localStorage.setItem("profileData", JSON.stringify(t))

    }
    const handleRemoveNewApprovalStatus = () =>
    {
        setApprovalRequestStatusData([])
        // localStorage.setItem("approvalRequestStatus", JSON.stringify([]))
    }
    const handleRemoveNewApprovalRequest = () =>
    {
        setNewApprovalRequestData([])
        localStorage.setItem("newApprovalRequest", JSON.stringify([]))
    }
    const handleRemoveGeneralData = () =>
    {
        setGeneralData([])
        localStorage.setItem("generalNotification", JSON.stringify([]))
    }
    const handleRemoveGeneralNotification = () =>
    {
        setNewGeneralNotification(false)
        localStorage.setItem("newGeneralNotification", JSON.stringify(false))
        let t = authCtx.profileData;
        t.generalNotificaions = 0;
        localStorage.setItem("profileData", JSON.stringify(t))
    }
    const handleRemoveHospitalNotificationMark = (data) =>
    {
        setNewNotification(false)
        localStorage.setItem("newNotification", JSON.stringify(false))
        setHospitalNumberOfNotification(0);
        localStorage.setItem("hospitalNumberOfNotification", JSON.stringify(0))
        let t = authCtx.profileData;
        t.emergencyRequests = 0;
        localStorage.setItem("profileData", JSON.stringify(t))
    }
    const handleSendUsageNotification = () =>
    {
        socket.emit("usageNotification")
    }

    const contextValue = {
        newNotification: newNotification,
        newGeneralNotification: newGeneralNotification,
        hospitalNumberOfNotification: hospitalNumberOfNotification,
        approvalRequestStatusData: approvalRequestStatusData,
        newApprovalRequestData: newApprovalRequestData,
        generalNotificationData: generalData,
        sendApprovalRequest: handleSendApprovalRequest,
        respondToApprovalRequest: handleRespondToApprovalRequest,
        removeNotification: handleRemoveNotification,
        removeNewApprovalStatus: handleRemoveNewApprovalStatus,
        removeNewApprovalRequest: handleRemoveNewApprovalRequest,
        removeGeneralNotificationData: handleRemoveGeneralData,
        removeGeneralNotification: handleRemoveGeneralNotification,
        handleRemoveHospitalNotificationMark: handleRemoveHospitalNotificationMark,
        handleSendUsageNotification: handleSendUsageNotification,
    }


    return (
        <SocketContext.Provider value={contextValue}>
            {props.children}
        </SocketContext.Provider>
    )
}

export default SocketContext;