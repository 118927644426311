import React, { useContext, Suspense } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
// import  io from "socket.io-client";

import AuthContext from "./store/auth-context";
import LoadingSpinnerFullScreen from './components/ui/LoadingSpinnerFullScreen';

const SuperAdminHome = React.lazy(() => import('./pages/SuperAdmin/SuperAdminHome'))
const SeniorAdminHome = React.lazy(() => import('./pages/SeniorAdmin/SeniorAdminHome'))
const Login = React.lazy(() => import('./pages/Login/Login'))
const AdminHome = React.lazy(() => import('./pages/Admin/AdminHome'))
const HospitalHome = React.lazy(() => import('./pages/Hospital/HospitalHome'))
const PharmacyHome = React.lazy(() => import('./pages/Pharmacy/PharmacyHome'))
const LabHome = React.lazy(() => import('./pages/Lab/LabHome'))
const XRayHome = React.lazy(() => import('./pages/XRay/XRayHome'))
function App()
{
  const authCtx = useContext(AuthContext);

  window.history.replaceState({}, document.title);

  return (
    <Suspense fallback={<LoadingSpinnerFullScreen isLoading={true} />}>
      <Routes >
        {(!authCtx.isLoggedIn) && <Route path="/login" element={<Login />} />
        }
        {(authCtx.isLoggedIn && authCtx.role) &&
          < Route path="/login" element={<Navigate to="/" replace={true} />} />
        }
        {/* Super Admin */}
        {(authCtx.isLoggedIn && authCtx.role === "superAdmin") &&
          < Route path="/*" element={<SuperAdminHome />} />
        }

        {/* Admin */}
        {(authCtx.isLoggedIn && authCtx.role === "admin") &&
          < Route path="/*" element={<AdminHome />} />
        }

        {/* Senior Admin */}
        {(authCtx.isLoggedIn && authCtx.role === "doctor") &&
          < Route path="/*" element={<SeniorAdminHome />} />
        }

        {/* Hospital */}
        {(authCtx.isLoggedIn && authCtx.role === "hospital") &&
          < Route path="/*" element={<HospitalHome />} />
        }

        {/* Pharmacy */}
        {(authCtx.isLoggedIn && authCtx.role === "pharmacy") &&
          < Route path="/*" element={<PharmacyHome />} />
        }

        {/* Lab */}
        {(authCtx.isLoggedIn && authCtx.role === "lab") &&
          < Route path="/*" element={<LabHome />} />
        }

        {/* XRay */}
        {(authCtx.isLoggedIn && authCtx.role === "xRay") &&
          < Route path="/*" element={<XRayHome />} />
        }

        {(authCtx.isLoggedIn && authCtx.role) ? <Route path="*" element={<Navigate to="/" replace={true} />} /> : <Route path="*" element={<Navigate to="/login" replace={true} />} />}


      </Routes >
    </Suspense>

  );
}

export default App;
